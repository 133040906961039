<template>
  <div class="transaction-detail">
    <b-row>
      <b-col cols="12" md="9">
        <p class="m-0 text-header bold">
          TRANSACTION DETAIL : {{ data?.invoiceNo }}
        </p>
      </b-col>
      <b-col cols="12" md="3">
        <div class="header right" v-if="data">
          <div
            class="status"
            :style="`background-color:${data.color};  ${
              data.outlineColor
                ? `color: ${data.outlineColor}; border: 1px solid ${data.outlineColor};`
                : 'color:white; '
            }`"
          >
            <p class="m-0">
              {{ data.statusName }}
            </p>
          </div>
        </div>
      </b-col>
    </b-row>

    <div class="detail">
      <div class="detail-header">
        <p class="m-0 bold text-white">Transaction Detail</p>
        <!-- <p class="m-0 bold">Date time transaction :</p> -->
      </div>
      <div class="detail-body" v-if="data">
        <b-row>
          <b-col cols="12" md="6">
            <div class="list-detail">
              <p class="m-0 bold mw-100">Full name</p>
              <p class="m-0 mr-2">:</p>
              <p class="m-0 w-full">
                {{ data.fullName }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="list-detail">
              <p class="m-0 bold mw-100">Gender</p>
              <p class="m-0 mr-2">:</p>
              <p class="m-0 w-full">
                {{ data.genderName }}
              </p>
            </div>
          </b-col>

          <b-col cols="12" md="6" v-if="visibleIdCard">
            <div class="list-detail">
              <p class="m-0 bold mw-100">ID Card</p>
              <p class="m-0 mr-2">:</p>
              <p class="m-0 w-full">
                {{ data.idCard }}
              </p>
            </div>
          </b-col>

          <b-col cols="12" md="6" v-if="visibleIdCard">
            <div class="list-detail">
              <p class="m-0 bold mw-100">IDCard Image</p>
              <p class="m-0 mr-2">:</p>
              <p class="m-0 w-full">
                <a href="#" @click="$refs.base64Modal.show()"
                  >แสดงบัตรประชาชน</a
                >
              </p>
            </div>
          </b-col>

          <b-col cols="12" md="6">
            <div class="list-detail">
              <p class="m-0 bold mw-100">Email</p>
              <p class="m-0 mr-2">:</p>
              <p class="m-0 w-full">
                {{ data.email || "-" }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="list-detail">
              <p class="m-0 bold mw-100">Round</p>
              <p class="m-0 mr-2">:</p>
              <p class="m-0 w-full">
                {{
                  $moment(data.bookingDate).locale("th").format("Do MMMM YYYY")
                }}
                {{ data.startTimeShow }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="list-detail">
              <p class="m-0 bold mw-100">Telephone</p>
              <p class="m-0 mr-2">:</p>
              <p class="m-0 w-full">
                {{ data.telephone || "-" }}
              </p>
            </div>
          </b-col>

          <b-col cols="12" md="6">
            <div class="list-detail">
              <p class="m-0 bold mw-100">Net Pirce</p>
              <p class="m-0 mr-2">:</p>
              <p class="m-0 w-full">
                {{ formatNumber(data.grandTotal) }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="list-detail">
              <p class="m-0 bold mw-100">Invoice No</p>
              <p class="m-0 mr-2">:</p>
              <p class="m-0 w-full">
                {{ data.invoiceNo || "-" }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="list-detail">
              <p class="m-0 bold mw-100">Transaction Id</p>
              <p class="m-0 mr-2">:</p>
              <p class="m-0 w-full">
                {{ data.transactionShowId }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="list-detail">
              <p class="m-0 bold mw-100">Note</p>
              <p class="m-0 mr-2">:</p>
              <p class="m-0 w-full">
                {{ data.note || "-" }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6"> </b-col>

          <b-col cols="12" md="6">
            <div class="list-detail">
              <p class="m-0 bold mw-100">Promocode</p>
              <p class="m-0 mr-2">:</p>
              <p class="m-0 w-full">
                {{ data.promocodeSerialNo || "-" }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="list-detail">
              <p class="m-0 bold mw-100">Serial</p>
              <p class="m-0 mr-2">:</p>
              <p class="m-0 bold w-full">
                {{ data.promoCodeName || "-" }}
              </p>
            </div>
          </b-col>

          <b-col cols="12" md="6" v-if="data.customerFlowStatusId === 2">
            <div class="list-detail">
              <p class="m-0 bold mw-100">Cancel Note</p>
              <p class="m-0 mr-2">:</p>
              <p class="m-0 w-full">
                {{ data.canceledNote || "-" }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6" v-if="data.customerFlowStatusId === 2">
          </b-col>

          <b-col cols="12" md="6">
            <div class="list-detail">
              <p class="m-0 bold mw-100">Client</p>
              <p class="m-0 mr-2">:</p>
              <p class="m-0 w-full">
                {{ data.venueName || "-" }}
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="detail" v-if="visibleTestResult">
      <div class="detail-header">
        <p class="m-0 bold text-white">Test Result</p>
      </div>
      <div class="detail-body" v-if="data">
        <b-row>
          <b-col cols="12" md="6">
            <div class="list-detail">
              <p class="m-0 bold mw-100">File</p>
              <p class="m-0 mr-2">:</p>
              <p class="m-0 w-full">
                <a
                  :href="data.testResultUrl ? data.testResultUrl : '#'"
                  target="_blank"
                  class="tw-underline"
                >
                  {{ data.testResultUrl || "-" }}</a
                >
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="list-detail">
              <p class="m-0 bold mw-100">Time</p>
              <p class="m-0 mr-2">:</p>
              <p class="m-0 w-full">
                {{
                  data.testResultDate
                    ? $moment(data.testResultDate)
                        .locale("th")
                        .format("Do MMMM YYYY")
                    : "-"
                }}
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="detail" v-if="data">
      <div class="detail-header">
        <p class="m-0 bold text-white">Payment Info</p>

        <b-button
          class="btn-filter"
          @click="getPaymentInfo"
          v-if="data.showBtnFlag"
        >
          Payment
        </b-button>
      </div>
      <div class="detail-body" v-if="data">
        <b-row>
          <b-col cols="12" md="6">
            <div class="list-detail">
              <p class="m-0 bold mw-100">Payment Type</p>
              <p class="m-0 mr-2">:</p>
              <p class="m-0 w-full">
                {{ data.paymentTypeName }}
              </p>
            </div>

            <div class="list-detail">
              <p class="m-0 bold mw-100">Payment Ref</p>
              <p class="m-0 mr-2">:</p>
              <p class="m-0 w-full">
                {{ data.paymentTransactionId || "-" }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="list-detail">
              <p class="m-0 bold mw-100">Payment Time</p>
              <p class="m-0 mr-2">:</p>
              <p class="m-0 w-full">
                {{ data.paymentTime || "-" }}
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="detail" v-if="data && data.transactionHistory.length > 0">
      <div class="tw-mt-4 tw-mb-2 tw-text-base">
        <p class="m-0 bold">Transaction Update History</p>
      </div>
      <div v-if="data && data.transactionHistory.length > 0">
        <HistoryUpdateTable :items="data.transactionHistory" :isBusy="isBusy" />
      </div>
    </div>

    <div class="detail" v-if="data && data.hasBilling == 1">
      <SectionAddress :data="transactionAddress" />
    </div>

    <div class="table" v-if="items.length > 0">
      <Table
        :items="items"
        :footerItem="footerItem"
        :fields="fields"
        :isBusy="isBusy"
        :rows="rows"
        :pageOptions="pageOptions"
      />
    </div>

    <ModalConfirmPayment
      :dataPayment="dataPayment"
      @confirmSuccess="confirmSuccess"
    />
    <ModalTextArea
      id="cancel"
      ref="cencelModal"
      text="You want cancel this booking"
      @handler="handleCancel"
      showIcon
      label="The reason for cancellation."
      placeholder="You can specify the reason for cancellation of the booking."
    />
    <ModalBase64
      v-if="data?.idCardImgUrl"
      ref="base64Modal"
      :base64="data.idCardImgUrl"
      title="IDCard Image"
    />

    <div
      class="btn-display"
      v-if="
        data && data.customerFlowStatusId > 2 && data.customerFlowStatusId < 6
      "
    >
      <b-button class="btn-cancel" @click="onClickCancel">Cancel</b-button>
    </div>
  </div>
</template>

<script>
import Table from "./component/Table.vue";
import ModalConfirmPayment from "@/components/modal/ModalConfirmPayment.vue";
import ModalTextArea from "@/components/modal/ModalTextArea";
import SectionAddress from "../component/SectionAddress.vue";
import ModalBase64 from "@/components/modal/ModalBase64.vue";
import HistoryUpdateTable from "../component/HistoryUpdateTable.vue";
export default {
  components: {
    Table,
    ModalConfirmPayment,
    ModalTextArea,
    SectionAddress,
    ModalBase64,
    HistoryUpdateTable,
  },
  data() {
    return {
      data: null,
      dataPayment: null,

      // state table
      items: [],
      footerItem: {},

      fields: [
        {
          key: "productName",
          label: "Product Name",
        },
        {
          key: "quantity",
          label: "Quantity",
        },
        {
          key: "subtotalPrice",
          label: "Subtotal Price",
        },
        {
          key: "discountPrice",
          label: "Discount",
        },
        {
          key: "price",
          label: "Price",
        },
      ],
      isBusy: false,
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      transactionAddress: null,
      levelReadIDCard: 15,
      levelReadTestResult: 18,
    };
  },
  computed: {
    visibleIdCard() {
      return !!this.$store.state.permission.Permission.find(
        (e) => e.permissionMenuId === this.levelReadIDCard
      );
    },
    visibleTestResult() {
      return !!this.$store.state.permission.Permission.find(
        (e) => e.permissionMenuId === this.levelReadTestResult
      );
    },
  },
  mounted() {
    this.getDetailTransaction();
    // this.getPaymentInfo();
  },
  methods: {
    async getDetailTransaction() {
      let payload = {};
      if (this.$isEventGroup !== undefined) {
        payload = {
          isEventGroup: this.$isEventGroup,
        };
      }

      const getData = await this.$services.transaction.getTransactionDetail(
        this.$route.params.id,
        payload
      );

      if (getData.result == 1) {
        this.data = getData.detail;
        this.items = getData.detail.productTable;
        this.footerItem = getData.detail.summaryProduct;
        if (getData.detail.hasBilling > 0) {
          this.transactionAddress = { ...getData.detail.billingDetail };
        }
      }
    },
    async getPaymentInfo() {
      const getData = await this.$services.transaction.getPaymentInfo(
        this.$route.params.id
      );

      if (getData.result == 1) {
        // add state base64
        getData.detail.bankTransferList = getData.detail.bankTransferList.map(
          (x) => {
            // change format dateonly
            x.transactionDetail.dateOnly = x.transactionDetail.dateOnly
              .split("-")
              .reverse()
              .join("-");

            // add new key to obj
            let obj = {
              ...x,
              base64: {
                base64: null,
              },
              imageName: x.transactionDetail.slipImgUrl.split("/").reverse()[0],
            };
            // conver time

            x.transactionDetail.timeOnly = this.convertTime(
              x.transactionDetail.timeOnly
            );

            return obj;
          }
        );

        this.dataPayment = getData.detail;

        this.$bvModal.show("modal-confirm-payment");
      }
    },
    onClickCancel() {
      this.$refs.cencelModal.show();
    },
    async handleCancel(val, text) {
      if (val === true) {
        const respone = await this.$services.transaction.cancelTransaction(
          this.$route.params.id,
          text
        );
        this.getDetailTransaction();
      } else {
        this.$refs.cencelModal.close();
      }
    },
    convertTime(time) {
      let getTime = `${this.$moment().format("YYYY-MM-DDT")}${time}`;

      return getTime;
    },

    convertTimeHHMM(time) {
      return this.$moment(time).format("HH:mm");
    },

    confirmSuccess() {
      this.getDetailTransaction();
    },
    formatNumber(val) {
      return this.$services.utils.formatNumber(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.transaction-detail {
  // font

  .text-header {
    font-size: 18px;
  }

  .bold {
    font-weight: bold;
  }

  .text-white {
    color: var(--font-color);
  }

  .header {
    &.right {
      display: flex;
      justify-content: flex-end;
    }

    .status {
      padding: 5px 15px;
      border-radius: 15px;
    }
  }

  .detail {
    .detail-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--secondary-color);
      padding: 10px;
      margin-top: 10px;
    }

    .detail-body {
      background: white;
      padding: 10px;

      .list-detail {
        display: flex;
        margin-bottom: 5px;

        .mw-100 {
          min-width: 100px;
        }

        .w-full {
          width: 100%;
          text-align: right;
        }
      }
    }
  }

  .table {
    margin-top: 10px;
  }
  .btn-display {
    display: flex;
    justify-content: flex-end;
    .btn-cancel {
      color: red;
      background-color: white;
      border-color: red;
      width: 100px;
    }
    .btn-cancel:hover {
      color: white;
      background-color: red;
      border-color: red;
      width: 100px;
    }
  }
}

// ! override
.btn-filter {
  min-width: 100px;
}
</style>
