<template>
  <div v-if="modalShow && base64">
    <b-modal
      v-model="modalShow"
      class="base64Modal"
      ref="base64Modal"
      :title="title"
      hide-footer
      no-close-on-backdrop
      centered
      body-class="p-4"
      @hidden="handleCloseModal"
      ><img v-bind:src="base64" width="100%" height="500"
    /></b-modal>
  </div>
</template>

<script>
export default {
  name: "ModalBase64",
  props: {
    base64: {
      type: String || null,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalShow: false,
      data: "",
    };
  },
  methods: {
    show() {
      this.modalShow = true;
    },
    close() {
      this.modalShow = false;
    },
    handleOkModal() {
      this.$emit("handler", true);
      this.data = "";
      this.modalShow = false;
    },
    handleCloseModal() {
      this.$emit("handler", false);
      this.data = "";
      this.modalShow = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
