<template>
  <div>
    <b-row>
      <b-col
        ><b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(createdDate)="{ item }">
            <p class="m-0">
              {{
                $moment(item.createdDate).locale("th").format("Do MMMM YYYY")
              }}
              ,
              {{ $moment(item.createdDate).locale("th").format("HH:mm") }}
            </p>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template></b-table
        ></b-col
      ></b-row
    >
  </div>
</template>

<script>
export default {
  name: "HistoryUpdateTable",
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    isBusy: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "createdDate",
          label: "UpdateDate",
        },
        {
          key: "oldInvoiceNo",
          label: "OldInvoiceNo",
        },
        {
          key: "newInvoiceNo",
          label: "NewInvoiceNo",
        },
        {
          key: "oldGrandTotal",
          label: "OldGrandTotal",
        },
        {
          key: "newGrandTotal",
          label: "NewGrandTotal",
        },

        {
          key: "differencePrice",
          label: "DifferencePrice",
        },
        {
          key: "updatedType",
          label: "UpdatedType",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
